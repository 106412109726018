<template>
  <div class="pt-6 pb-12 space-y-6">
    <SimpleAlert
      v-if="hasPermission('settings_manage') && authOrganization.requires_billing_update"
      class="mx-6"
      type="danger"
      title="Billing Update Required"
      :hide-icon="true"
      description="Your subscription has outstanding invoices and your feature access may be limited soon."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.billing-settings.show')"
          class="mt-4 font-medium text-red-700 whitespace-nowrap hover:text-red-600"
          >Update Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="
        hasPermission('settings_manage') &&
        organizationNeedsBillingTaxDetails &&
        authOrganization.is_pro_account
      "
      class="mx-6"
      type="danger"
      title="Billing Update Required"
      :hide-icon="true"
      description="Some of your required billing information is missing from your account."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.billing-settings.show')"
          class="mt-4 font-medium text-red-700 whitespace-nowrap hover:text-red-600"
          >Update Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="hasPermission('app_store_view_any') && integrationRequiringAuthorization"
      class="mx-6"
      type="warning"
      title="Integration Update Required"
      :hide-icon="true"
      description="You have an integration that requires reauthorization. Jump over to your App Store to complete this update."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.app-store.show')"
          class="mt-4 font-medium text-primary-dark whitespace-nowrap hover:text-primary"
          >Update Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="hasPermission('app_store_view_any') && integrationRequiringConfigurationUpdate"
      class="mx-6"
      type="warning"
      title="Integration Update Required"
      :hide-icon="true"
      description="Your QuickBooks tax rates need updating."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.quickbooks.tax-rates.index')"
          class="mt-4 font-medium text-primary-dark whitespace-nowrap hover:text-primary"
          >Update Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-else-if="hasPermission('app_store_view_any') && integrationExpiringSoon"
      class="mx-6"
      type="warning"
      title="Integration Attention Needed"
      :hide-icon="true"
      description="You have an integration that will expire soon. Jump over to your App Store to complete this update."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.app-store.show')"
          class="mt-4 font-medium text-primary-dark whitespace-nowrap hover:text-primary"
          >Update Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="hasPermission('settings_manage') && portalAppConfigRequiringAction"
      class="mx-6"
      type="danger"
      title="Mobile App Action Required"
      :hide-icon="true"
      description="Your mobile app will not receive updates until you take action."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.customer-portal.app.dashboard.show')"
          class="mt-4 font-medium text-red-700 whitespace-nowrap hover:text-red-600"
          >View Now <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="hasPermission('settings_manage') && otherTeamMemberNeedsHomeBase"
      class="mx-6"
      type="info"
      title="Team Member Missing Home Base"
      :hide-icon="true"
      description="At least one of your team members is configured to use either dynamic drive times or travel fees, but does not have a home base set, yet."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.company-team-members.index')"
          class="mt-4 font-medium text-primary-dark whitespace-nowrap hover:text-primary"
          >View team members<span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="needsHomeBase"
      class="mx-6"
      type="info"
      title="Missing Home Base"
      :hide-icon="true"
      description="Your account is configured to use dynamic drive times and/or travel fees, but you do not have a home base set, yet."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.home-bases.index')"
          class="mt-4 font-medium text-primary-dark whitespace-nowrap hover:text-primary"
          >Set Home Base <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>

    <SimpleAlert
      v-if="!authOrganization.is_pro_account"
      class="mx-6"
      type="info"
      title="You are using Aryeo Lite"
      :hide-icon="true"
      description="Unlock powerful features to help manage your business with an Aryeo Pro Account."
    >
      <Typography as="p">
        <aryeo-link
          :href="route('web.company.billing.upgrade.show')"
          class="mt-4 font-medium text-info whitespace-nowrap hover:text-info-dark"
          >Learn more <span aria-hidden="true">&rarr;</span></aryeo-link
        >
      </Typography>
    </SimpleAlert>
    <SimpleAlert
      v-if="pendingMediaRequests.length > 0"
      class="mx-6 break-words"
      type="info"
      :title="`${pendingMediaRequests.length} ${pluralize(pendingMediaRequests.length, 'media request', 'media requests')}`"
      :hide-icon="true"
      :description="mediaRequestDescription"
    >
      <Typography as="p">
        <a
          :href="
            pendingMediaRequests.length === 1
              ? route('web.company.media-requests.manage.show', {
                  mediaRequest: pendingMediaRequests[0].id,
                })
              : route('web.company.media-requests.manage.index')
          "
          class="mt-4 font-medium text-info whitespace-nowrap hover:text-info-dark"
        >
          Manage {{ pluralize(pendingMediaRequests.length, 'request', 'requests') }}
          <span aria-hidden="true">&rarr;</span>
        </a>
      </Typography>
    </SimpleAlert>

    <div
      class="flex flex-col mx-6 space-y-3"
      v-if="hasPermission('orders_manage') || hasPermission('listing_create')"
    >
      <InertiaButton
        v-if="hasPermission('listing_create')"
        :link="route('web.company.listings.create')"
        text="Create Listing"
      />

      <InertiaButton
        v-if="hasPermission('orders_manage') && authOrganization.is_pro_account"
        :link="route('web.company.orders.index', { create: true })"
        color="secondary"
        isSecondary
        text="Create Order"
      />

      <InertiaButton
        v-if="!authOrganization.is_pro_account"
        :link="route('web.company.billing.upgrade.show')"
        color="secondary"
        isSecondary
        text="Upgrade Account"
      />

      <div>
        <hr class="h-1 mt-3" />
      </div>
    </div>

    <div class="space-y-6">
      <SidebarSection
        v-for="section in usePrimaryNavigation().sections.filter((section) => section.show)"
        :key="section.name"
        :name="section.name"
      >
        <SidebarItem
          v-for="item in section.children.filter((item) => item.show && item.targetRouteName)"
          :key="item.name"
          :name="item.name"
          :disabled="item.disabled"
          :image="item.image"
          :icon-component="item.icon"
          :target-route-name="item.targetRouteName"
          :target-route-attributes="item.targetRouteAttributes"
          :is-inertia-link="item.routeIsInertia"
          :route-names-to-check-if-current="item.routeNamesToCheckIfCurrent"
          :ghost="item.ghost || false"
          :badge="item.badge"
          item-of-primary-column
        />
      </SidebarSection>
    </div>

    <div class="flex-col space-y-6 md:hidden">
      <hr class="h-1 mx-6" />

      <SidebarSection name="Current Group" :use-negative-margin="false">
        <CompanyDropdown :dark-mode="false" />
      </SidebarSection>

      <hr class="h-1 mx-6" />

      <SidebarSection :use-negative-margin="false">
        <form method="post" :action="route('web.company.login.destroy')" class="h-full">
          <CsrfToken />
          <button
            type="submit"
            class="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:text-gray-900 hover:bg-gray-50 group"
          >
            <LogoutIcon
              class="w-5 h-5 mr-3 text-gray-500 shrink-0 group-hover:text-gray-600"
              aria-hidden="true"
            />
            Logout
          </button>
        </form>
      </SidebarSection>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { LogoutIcon } from '@heroicons/vue/outline'

import { useAuth } from '@/Hooks/Company/useAuth'
import { usePermissions } from '@/Hooks/Company/usePermissions'

import usePrimaryNavigation from '@/Hooks/Company/Navigation/usePrimaryNavigation'

import CompanyDropdown from '@/Components/Company/Header/CompanyDropdown'

import { Typography } from '@aryeo/runway'

import SidebarItem from '@/Components/Company/Sidebar/SidebarItem'
import InertiaButton from '@/Components/Shared/Buttons/InertiaButton'
import CsrfToken from '@/Components/Shared/CsrfToken'
import SidebarSection from '@/Components/Shared/Navigation/Sidebar/SidebarSection'

import SimpleAlert from '@/Runway/Alerts/Simple'

import { pluralize } from '@/Utils/StringFormatter'

const {
  authOrganization,
  integrationRequiringAuthorization,
  integrationRequiringConfigurationUpdate,
  integrationExpiringSoon,
  needsHomeBase,
  otherTeamMemberNeedsHomeBase,
  pendingMediaRequests,
  portalAppConfigRequiringAction,
  organizationNeedsBillingTaxDetails,
} = useAuth()
const { hasPermission } = usePermissions()

const mediaRequestDescription = computed(() => {
  return pendingMediaRequests.value.length > 1
    ? 'You’ve received multiple media requests to assign to an existing company or create a new company to handle the requests.'
    : `You’ve received a media request to ${pendingMediaRequests.value[0].user.email}. Assign this request to an existing company or create a new company to handle the request.`
})
</script>
