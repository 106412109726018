<template>
  <div
    v-if="isImpersonating"
    class="flex gap-4 max-sm:flex-col items-center justify-center text-center bg-amber-100 text-warning-dark px-3 py-2 font-[sans-serif]"
  >
    <p class="text-base">You are currently in impersonation mode.</p>

    <div class="flex gap-2">
      <TextButton
        variant="body-small"
        color="warning"
        @click="stopImpersonating"
        text="Exit"
        icon-name="signOut"
      />
    </div>
  </div>
</template>

<script setup>
import { useAuth } from '@/Hooks/Company/useAuth'
import { TextButton } from '@aryeo/runway'

const { isImpersonating, stopImpersonating } = useAuth()
</script>
